import * as React from "react"
import imgVertical1 from "../../images/use_case_vertical1.svg"
import imgVertical2 from "../../images/use_case_vertical2.svg"
import headingImage from "../../images/logo_pfizer.svg"
import {
  PaddedContent,
  PageHeading,
  PageHeadingParagraph,
  PageWrapper,
  WithCollapsableSideImage,
} from "../../components/modern/layout"
import {
  ContentParagraph,
  List,
  ListItem,
  TitleStripe,
  Quote,
} from "../../components/modern/content"
import { Box } from "theme-ui"

const PfizerPage = () => {
  return (
    <PageWrapper title="Pfizer" pathname="/case-study/pfizer">
      <PageHeading
        title="Making genetic data accessible for drug discovery"
        imageSrc={headingImage}
      >
        <PageHeadingParagraph>
          Tweag collaborated with Pfizer to enhance its search engine for human
          genetic data to help advance scientific discovery.
        </PageHeadingParagraph>
        <PageHeadingParagraph>
          Human genetic data holds the key to creating new medicines to treat
          disease. However, the amount of data doubles every seven months – this
          rapid growth makes it difficult for scientists to zero in on the
          specific information they need in drug discovery.
        </PageHeadingParagraph>
        <PageHeadingParagraph>
          To help solve this challenge, Pfizer created the Table of Everything
          (ToE): a dynamic internal database that catalogues almost 20,000 human
          genes and their related diseases and traits, and automatically updates
          weekly. It allows scientists to quickly access information related to
          a specific gene.
        </PageHeadingParagraph>
      </PageHeading>

      <PaddedContent>
        <Box
          sx={{
            maxWidth: `100em`,
            marginX: `auto`,
            marginY: `3em`,
          }}
        >
          <WithCollapsableSideImage imageSrc={imgVertical1} imageSide="left">
            <Box
              sx={{
                fontSize: [`1.2em`, `1.5em`],
                maxWidth: `50em`,
              }}
            >
              <ContentParagraph>
                Pfizer partnered with Tweag to maintain and enhance the
                application to meet the evolving needs of its scientists. In
                support of this goal, Tweag:
              </ContentParagraph>
              <List>
                <ListItem>
                  Introduced modern development techniques and automation to
                  increase efficiency and performance
                </ListItem>
                <ListItem>
                  Remodelled the user interface to improve usability
                </ListItem>
                <ListItem>
                  Enhanced the database architecture to increase efficiency
                </ListItem>
                <ListItem>
                  Updated the software to better integrate with other components
                  and systems
                </ListItem>
                <ListItem>
                  Added features to serve the needs of both power users and
                  casual users
                </ListItem>
              </List>
            </Box>
          </WithCollapsableSideImage>
        </Box>
      </PaddedContent>
      <TitleStripe title="Fast & iterative collaboration" bgColor="beige">
        <ContentParagraph>
          Tweag used a fast, iterative approach to develop the ToE to meet
          Pfizer’s needs. Tweag engineers embedded with Pfizer’s R&D to iterate
          on a weekly basis. Each week, Tweag deployed a test version with
          Pfizer’s latest requests and changes. This rapid feedback loop enabled
          Pfizer to develop the ToE to meet their evolving needs, quickly and
          cost-efficiently.
        </ContentParagraph>
      </TitleStripe>
      <TitleStripe title="Software & bioinformatic experts" bgColor="green">
        <ContentParagraph>
          Unlike typical software engineers, Tweag engineers have domain
          expertise in bioinformatics and biology. This gave Tweag engineers a
          richer understanding of Pfizer’s goals and needs, so they could
          recommend the right specs to achieve them. Tweag’s bioinformatic
          expertise saved Pfizer time and money in software development.
        </ContentParagraph>
      </TitleStripe>
      <TitleStripe title="Result" bgColor="grey">
        <List>
          <ListItem>
            The Table of Everything was recognized with a prestigious R&D
            achievement award from Pfizer.
          </ListItem>
          <ListItem>
            The Table of Everything was recognized with a prestigious R&D
            achievement award from Pfizer. Today, many Pfizer scientists in
            early discovery use the enhanced ToE to hunt for new potential
            targets to treat diseases.
          </ListItem>
          <ListItem>
            The Table of Everything was recognized with a prestigious R&D
            achievement award from Pfizer. The enhanced ToE has also enabled
            Pfizer to collaborate with scientists across the industry,
            contributing to the efforts of several industry-wide genetic
            databases.
          </ListItem>
        </List>
      </TitleStripe>
      <PaddedContent>
        <Box
          sx={{
            maxWidth: `45em`,
            marginX: `auto`,
            marginY: `3em`,
          }}
        >
          <WithCollapsableSideImage imageSrc={imgVertical2} imageSide="left">
            <Quote
              quote="I really value Tweag’s rapid prototyping and their creativity and willingness to try different things."
              author="Eric Fauman, Senior Scientific Director, Head Integrative Biology, Pfizer"
            />
          </WithCollapsableSideImage>
        </Box>
      </PaddedContent>
    </PageWrapper>
  )
}

export default PfizerPage
